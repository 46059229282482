import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Main from "../components/main";
import { Box, Flex, Link } from "rebass/styled-components";
import { H3, H2 } from "../components/headers";
import Text from "../components/text";

const Covid19 = () => {
    return (
        <>
            <SEO title="Book a Covid Booster Jab" />
            <Layout>
                <Main p={[3, 5, 5]} mb={4}>
                    <Flex flexWrap="wrap">
                        <Box width={1}>
                            <Text color="white" fontSize={1} fontWeight="regular" my={3}>
                                We at Avicenna, your local community pharmacy, are proud to support the NHS in delivering COVID-19 vaccinations and boosters in England and Wales. 19 Avicenna branches now feature a vaccination hub, where patients can access a COVID-19 vaccination or booster.
                            </Text>
                        </Box>
                        <Box width={1}>
                            <H2 mb={1} color="white">Coronavirus (COVID-19) booster vaccine</H2>
                            <Text color="white" fontSize={1} fontWeight="regular" my={3}>
                                &bull; A coronavirus (COVID-19) booster vaccine dose helps improve the protection you have from your first 2 doses of the vaccine.
                            </Text>
                            <Text color="white" fontSize={1} fontWeight="regular" my={3}>
                                &bull; It helps give you longer-term protection against getting seriously ill from COVID-19.
                            </Text>
                            <Text color="white" fontSize={1} fontWeight="regular" my={3}>
                                Customers in England can book their vaccination or booster via the following link:
                            </Text>
                            <H3 mb={1} color="white">
                                <Link
                                    variant="bigLink"
                                    href="https://www.nhs.uk/conditions/coronavirus-covid-19/coronavirus-vaccination/book-coronavirus-vaccination/"
                                    color="white"
                                >
                                     NHS COVID-19 Vaccine and Booster Service
                                </Link>
                            </H3>
                            <Text color="white" fontSize={1} fontWeight="regular" my={3}>
                                Customers in Wales can find out further details on vaccination and booster appointments by following this link:
                            </Text>
                            <H3 mb={1} color="white">
                                <Link
                                    variant="bigLink"
                                    href="https://phw.nhs.wales/topics/immunisation-and-vaccines/covid-19-vaccination-information/eligibility-for-the-vaccine/"
                                    color="white"
                                >
                                     NHS Wales Immunisation and Vaccines Information
                                </Link>
                                <br />
                                &nbsp;
                            </H3>
                        </Box>
                    </Flex>
                </Main>
            </Layout>
        </>
    );
}

export default Covid19;
